import React from 'react'
import '../App.css'
function Topics() {
    return (
        <div>
            <section id="topics" className="topics pt-120 pb-120  brd-bottom">
            
            <div className="container">
               
               <div className="row">
                  <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
                     <h1 className="title">EVENTS</h1>
                     {/* <p className="title-lead mt-10">Whether you’re new to the speaking field or a seasoned pro  you’ll connect<br/> with old colleagues and make valuable new friends.</p> */}
                  </div>
               </div>
              
            </div>
            
            <div className="container">
               <div className="row vertical-align">
                  <div className="col-md-6 col-sm-6">
                     <div className="col-md-12">
                        <h3 className="sub-title-0  mb-20"><span className="gradient-text">Events</span></h3>
                     </div>
                     <div className="col-md-6">
                        <ul className="block-topic">
                           <li> Bizz नारी (KSOM+KSRM)</li>
                           <li>Advocacy (KSOL)</li>
                           <li>Robozone (KSOT - Core)</li>
                           <li>WebSec (KSOT-CS)</li>
                           <li> Genome (KSBT)</li>
                           
                        </ul>
                     </div>
                     <div className="col-md-6">
                        <ul className="block-topic">
                           <li>Hawk-Eye:( Marketing+Design Workshop)</li>
                           <li>LinkedIn Decoded </li>
                        </ul>
                     </div>
                  </div> 
                  <div className="col-md-6 col-sm-6">
                     <div className="block-video">
                        {/* <iframe width="1349" height="498" src="https://youtu.be/Z0DemkvYNU8" frameborder="0"
                           allow="accelerometer; autoplay=1;color=#ffffff; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                           allowfullscreen></iframe> */}
                        <iframe width="768" height="432" src="https://www.youtube.com/embed/Z0DemkvYNU8" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>

                        
                     </div>
                  </div>
               </div>
               
            </div>
         </section>
        </div>
    )
}

export default Topics
