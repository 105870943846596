import React, { useEffect } from "react";
import { ReactTypeformEmbed } from "react-typeform-embed";

function CampusAmbas() {
  //   React.useEffect(() => {
  //     //
  //   },[]);
  useEffect(() => {
    document.title = "Campuspreneur KSK'21";
  }, []);
  return (
    // <div>
    //     <html> <head> <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"> <title>KSK'21 Campus Ambassador (copy)</title> <style type="text/css">  </style> </head> <body>  <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script> </body> </html>

    //     <iframe id="typeform-full" width="100%" height="100%" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/taovfeos?typeform-medium=embed-snippet"></iframe>
    // </div>
    <ReactTypeformEmbed url="https://ksk-ca.typeform.com/to/taovfeos" />
  );
}

export default CampusAmbas;
