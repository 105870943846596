import React from 'react'
import '../App.css'
function Footer() {
    return (
        <div>
            <footer className="pt-120  bg-dark">
            {/*<section className="subsribe text-center ">
              
               {/* <div className="container">
                  
                  <div className="row">
                     <div className="col-sm-12  mb-30">
                        <h1 className="title">Subscribe for the latest updates</h1>
                     </div>
                  </div>
                 
               </div>
               
               <div className="container ">
                  <div className="row">
                     <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                        <form method="post" className="subscribe-form form" action="#">
                           <div className="col-sm-8">
                              <input placeholder="Enter your email address" value="" id="user-name" name="name" type="text"/>
                           </div>
                           <div className="col-sm-4">
                              <input value="Subscribe" className="but  submit" type="submit"/>
                           </div>
                           <div className="col-sm-12">
                              <p>* We don’t share your information with anyone.</p>
                           </div>
    </form>
                     </div>
                  </div>
                  <hr/>
               </div> */}
               
            {/* </section> */}
            <section className="top-footer pb-120">
              
               <div className="container ">
                  <div className="row">
                     <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                        <h2 className="sub-title-3 mb-30" style={{textAlign:"center"}}>About</h2>
                        <p className="" style={{textAlign:"center"}}>KIIT E-Cell has always been a platform which promotes entrepreneurship and helps in developing entrepreneurial abilities to combat against all the odds that a young entrepreneur goes through.</p>
                        <ul className="block-social mt-20 col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                           <li><a href="https://www.facebook.com/kiitecell/" target="_blank"><i className="icon-facebook"></i></a></li>
                           <li><a href="https://twitter.com/kiit_ecell?lang=en" target="_blank"><i className="icon-twitter"></i></a></li>
                           <li><a href="https://www.youtube.com/channel/UCvfLONcdrYLvzXndMlOIRYw/videos" target="_blank"><i className="icon-youtube"></i></a></li>
                           <li><a href="https://www.instagram.com/ecell_kiit/?hl=en" target="_blank"><i className="icon-instagram-1"></i></a></li>
                           <li><a href="https://www.linkedin.com/company/kiit-e-cell" target="_blank"><i className="icon-linkedin"></i></a></li>
                        </ul>
                     </div>
                  </div>
               </div>
               	
            </section>
            
         </footer>
        </div>
    )
}

export default Footer
