import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./Main";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import NewLogin from "./Components/NewLogin";
import CheckOut from "./Components/CheckOut";
import ProtectedRoute from "../src/Components/ProtectedRoute";
import PaymentSuccess from "../src/Components/PaymentSuccess";
import AddressForm from "../src/Components/AddressForm";
import CampusAmbas from "./Components/CampusAmbas";

function App() {
  return (
    <Switch>
     
      <Route exact path="/login" component={NewLogin} />
      <Route exact path="/forgotpassword" component={Signup} />
      {/* <ProtectedRoute exact path="/checkout" component={CheckOut} /> */}
      <Route exact path="/ca" component={CampusAmbas} />
      <Route path="/:ref_id" component={Main} />
       <Route path="/" component={Main} />
      <Route component={Error} />
    </Switch>
  );
}

export default App;
