import React, { useState } from "react";
import "../App.css";
import $ from "jquery";
window.jQuery = $;
require("venobox");
function Schedule() {
  React.useEffect(() => {
    $(".background-img").each(function () {
      var l = $(this).children("img").attr("src");
      $(this)
        .css("background-image", 'url("' + l + '")')
        .css("background-position", "initial");
    });
  }, []);
  const [isActive1, setActive1] = useState("true");

  const handleToggle1 = () => {
    setActive1("true");
    setActive2("");
    setActive3("");
  };
  const [isActive2, setActive2] = useState("");

  const handleToggle2 = () => {
    setActive2("true");
    setActive1("");
    setActive3("");
  };
  const [isActive3, setActive3] = useState("");

  const handleToggle3 = () => {
    setActive3("true");
    setActive1("");
    setActive2("");
  };

  return (
    <div>
      <section id="schedule" className="schedule pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
              <h1 className="title">Konclave Schedule</h1>
              {/* <p className="title-lead mt-10">We are very excited about the program being planned for Events 2017 <br/> this summer in Cincinati, Ohio.</p> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="sub-title-0  mb-25">
                <span className="gradient-text">Konclave Days</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-4 ">
              <ul className="block-tabs">
                <li
                  className={isActive1 ? "active" : null}
                  onClick={handleToggle1}
                >
                  <i className="et-line-calendar"></i>
                  <span>- Events</span>
                </li>
                 <li
                  className={isActive2 ? "active" : null}
                  onClick={handleToggle2}
                >
                  <i className="et-line-calendar"></i>
                   <span>- Workshops</span>
                </li>
                {/*<li
                  className={isActive3 ? "active" : null}
                  onClick={handleToggle3}
                >
                  <i className="et-line-calendar"></i>
                  <strong>Day 2</strong> <span>- 18th April 2021</span>
                </li> */}
              </ul>
            </div>
            <div className="col-sm-8 ">
              <ul className="block-tab">
                <li className={isActive1 ? "active" : null}>
                  <div className="block-date">
                    <i className="et-line-calendar"></i>
                    <strong>The Day</strong> <span>- 14th November 2021</span>
                  </div>
                  <div className="block-detail">
                    <span className="time">9:30am - 10:00am</span>
                    <span className="topic">Opening Ceremony</span>
                    <div className="block-text">
                      <p>Detailed Explanation about our upcoming events</p>
  
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">10:00am - 11:00am</span>
                    <span className="topic">Keynote Speaker</span>
                    <div className="block-text">
                      {/* <p>Detailed Explanation about our upcoming events</p> */}
  
                    </div>
                  </div>
                  
                  <div className="block-detail">
                    <span className="time">11:00am - 12:30pm</span>
                    <span className="topic">Robozone (KSOT - Core)</span>
                    <div className="block-text">
                      <p>
                      A panel discussion to get the audience accustomed to the latest industry trends, especially the ones related to IoT and Robotics, and how there is a wide range of opportunities even for the students pursuing their studies in core branches.
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">11:00am - 12:30pm</span>
                    <span className="topic">WebSec (KSOT-CS)</span>
                    <div className="block-text">
                      <p>
                      This panel discussion is going to cover the emerging concerns of Internet security. Ethical hacking and Network security are of utmost importance for the coming generations to be well acquainted with as technology is taking over our lives and there are always risks related to data leakage or theft. Also, to give an opportunity to students to pursue ethical hacking as a career in MNCs and the IT sector.

                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">11:00am - 12:30pm</span>
                    <span className="topic"> AgroTech (KSBT)</span>
                    <div className="block-text">
                      <p>
                         To make the students aware of the growing field of AgroTech and how they can make a change in this field by implementing rules and technologies of Bio-Technology</p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">12:30pm - 2:00pm</span>
                    <span className="topic">Bizz नारी (KSOM+KSRM)</span>
                    <div className="block-text">
                      <p>
                      A platform for female leaders from the Business world to inspire the next generation of alpha females, with their incredible journeys to the top.

                      </p>

                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">12:30pm - 2:00pm</span>
                    <span className="topic">Advocacy (KSOL)</span>
                    <div className="block-text">
                      <p>
                      This is a panel discussion that will enable the audience to get an insight into various laws that govern their venture and can help these new entrepreneurs to understand the legal needs of their organizations. Moreover, the knowledge of the law also makes the entrepreneurs comprehend its positive as well as negative impact on the business. This can in turn help the entrepreneurs to make better decisions to avoid any legal issues, while also ensuring consistent growth and profitability for the business.
                      </p>
                      
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">4:30pm - 5:00pm</span>
                    <span className="topic"> Closing Ceremony</span>
                    <div className="block-text">
                      <p>
                      
                      </p> 
                      
                     </div>
                  </div>
                  {/* <div className="block-detail">
                    <span className="time">4:30pm - 6:00pm</span>
                    <span className="topic">Industry 4.0 Workshop</span>
                    <div className="block-text">
                      <p>
                      In this event, the speakers will be creating awareness about the various startups using AI, IOT,AR/VR, Big Data and other latest technologies and how they taken over the tech world.
                      </p> */}
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> */}
                    {/* </div>
                  </div> */}
                </li>

                <li className={isActive2 ? "active" : null}>
                  <div className="block-date">
                    <i className="et-line-calendar"></i>
                    <span>- Workshops</span>
                  </div>
                  <div className="block-detail">
                    <span className="time">2:30pm - 4:30pm</span>
                    <span className="topic">Hawk-Eye:( Marketing+Design Workshop)</span>
                    <div className="block-text">
                      <p>
                      This session will be centered around marketing and how design and copywriting are essential to market a particular brand. 

                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Adam Blanco</a> </span> */}
                    </div>
                  </div>
                   <div className="block-detail">
                    <span className="date"><strong>7th November, 2021</strong></span>
                    <span className="time">3:00pm - 5:00pm</span>
                    <span className="topic">Linkedin Decoded</span>
                    <div className="block-text">
                      <p>
                      This session's major objective is to assist students in connecting with professionals all across the world by using LinkedIn.

                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Adam Blanco</a> </span> */}
                    </div>
                  </div>

                  

                  {/* <div className="block-detail">
                    <span className="time">2:00pm - 3:00pm</span>
                    <span className="topic">Tech Stree</span>
                    <div className="block-text">
                      <p>
                      It’s the 21st century and women today are at the top of their game. If you are a budding female entrepreneur in the field of tech and would want to someday have her own startup in the technical domain, then this event will bring to you a plethora of success stories shared by female leaders of today.
                      </p>
                      <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Luca Palermo</a> </span> 
                    </div>
                  </div> */}

                  {/* <div className="block-detail">
                    <span className="time">2:00pm - 4:00pm</span>
                    <span className="topic">B-PLAN</span>
                    <div className="block-text">
                      <p>
                      A fun competition that will engage you on real-life business problems that will equip you to think on your feet when making business decisions.
                      </p>
                      <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> 
                    </div>
                  </div> */}
                  {/* <div className="block-detail">
                    <span className="time">2:00pm - 3:00pm</span>
                    <span className="topic">Tech Stree</span>
                    <div className="block-text">
                      <p>
                        A little nervous being a female, thinking of building a
                        startup in this world? Don’t panic cause we got your
                        back, where you get to know the skills needed and share
                        your hardships through panel discussion with the
                        speakers and audience.
                      </p>
                       <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> 
                    </div>
                  </div> */}
                  {/* <div className="block-detail">
                    <span className="time">3:00pm - 4:00pm</span>
                    <span className="topic">Modern Day Columbus</span>
                    <div className="block-text">
                      <p>
                      This is a panel discussion to give the audience an idea on how to reach their target audience or on how to explore a new niche.
                      </p> */}
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Lina Blamberg </a> </span> */}
                    {/* </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">4:00pm - 5:00pm</span>
                    <span className="topic">Wellbeing at Workplace</span>
                    <div className="block-text">
                      <p>
                      In this event, the speakers will talk about how one can reduce the stress due to ‘work-from-home’ and maintain a healthy lifestyle along with work.
                      </p> */}
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    {/* </div>
                  </div> */}
                  {/* <div className="block-detail">
                              <span className="time">5::00pm - 6:00pm</span>
                              <span className="topic">Content Writing</span>
                              <div className="block-text">
                                 <p>This event brings an insight into being a content creator and monetizing your passion for it. Along with a teaching session to enlighten the qualities to become one.</p>
                                 {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Adam Blanco</a> </span>
                              </div>
                           </div> */}
                  
                  {/* <div className="block-detail">
                    <span className="time">5:00pm - 6:30pm</span>
                    <span className="topic">Gaming As A Career</span>
                    <div className="block-text">
                      <p>
                        {" "}
                        Had to leave gaming because of other obligations? Well,
                        we say don’t leave it rather, make it your career. As we
                        enlighten you the profit of gaming and earning revenue
                        through it.
                      </p> */}
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Adam Blanco</a> </span> */}
                    {/* </div>
                  </div> */}
                  {/* <div className="block-detail">
                    <span className="time">5:00pm - 6:30pm</span>
                    <span className="topic">Gaming As A Career Workshop</span>
                    <div className="block-text">
                      <p>
                        {" "}
                        Had to leave gaming because of other obligations? Well,
                        we say don’t leave it rather, make it your career. As we
                        enlighten you the profit of gaming and earning revenue
                        through it.
                      </p> */}
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Adam Blanco</a> </span> */}
                    {/* </div>
                  </div> */}
                </li>
                <li className={isActive3 ? "active" : null}>
                  <div className="block-date">
                    <i className="et-line-calendar"></i>
                    <strong>Day 2</strong> <span>18th April 2021</span>
                  </div>

                  <div className="block-detail">
                    <span className="time">10:30am - 1:00pm</span>
                    <span className="topic">
                      Investors Meet and Pitching Session (IMPS)
                    </span>
                    <div className="block-text">
                      <p>
                        {" "}
                        The mega event where you get to know what’s inside the
                        mind of an investor through panel discussion. And also a
                        platform to showcase your idea, cause who knows you
                        might get lucky getting an investment for your startup
                        right in there.
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text ">Luca Palermo</a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">10:30am - 12:00pm</span>
                    <span className="topic">Startup Neeti</span>
                    <div className="block-text ">
                      <p>
                      Every young entrepreneur should know the laws and legalities behind going into a business. This event will bring to you financial experts who will guide you through the nitty gritties of startup policies.
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">12:00pm - 1:00pm</span>
                    <span className="topic">Sustainable Startup</span>
                    <div className="block-text ">
                      <p>
                        Worried how the world would respond to your startup
                        regarding the environmental issues? Then turn your
                        attention here as we bring you the platform where you
                        get to explain how your business/startup works in a
                        cleaner way in accordance with the need of the hour.
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">2:00pm - 4:00pm</span>
                    <span className="topic">Brandpad</span>
                    <div className="block-text ">
                      <p>
                        Confused how to make your clients understand your brand?
                        Then what you are waiting for, know it where we bring a
                        workshop on building a brand and designing it in a way
                        that people recognize it, as this is the one.
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    </div>
                  </div>
                  {/* <div className="block-detail">
                    <span className="time">3:00pm - 4:00pm</span>
                    <span className="topic">Brandpad Workshop</span>
                    <div className="block-text ">
                      <p>
                        Confused how to make your clients understand your brand?
                        Then what you are waiting for, know it where we bring a
                        workshop on building a brand and designing it in a way
                        that people recognize it, as this is the one.
                      </p> */}
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span>   */}
                    {/* </div>
                  </div> */}

                  <div className="block-detail">
                    <span className="time">4:00pm - 5:30pm</span>
                    <span className="topic">Trading Cult</span>
                    <div className="block-text ">
                      <p>
                      This event will bring to you a real-life experience of 'Stocks' and how to handle finances like a professional.
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">5:30pm - 7:00pm</span>
                    <span className="topic">YouTuber Session</span>
                    <div className="block-text ">
                      <p>
                        A session where you get to see the one and only, Vipul
                        Goyal, the Indian stand-up comedian and actor, who is
                        counted among the top comedy performers in India, live
                        in action!
                      </p>
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    </div>
                  </div>
                  <div className="block-detail">
                    <span className="time">7:00pm - 8:30pm</span>
                    <span className="topic">Closing Ceremony</span>
                    <div className="block-text ">
                      {/* <span className="speaker"> <strong >Speaker</strong> : <a href="#" className="gradient-text "> Emilie Lippelt </a> </span> */}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Schedule;
