import React,{  createContext } from "react";
import "./css/paymentSuccess.css";
import swal from "@sweetalert/with-react";
import { Redirect } from "react-router-dom";
const paid=createContext();
function PaymentSuccess() {
  const redir = () => {
    swal("Payment Successful", "Thanks for registration", "success").then(
      (value) => {
        <paid.Provider value={true}>
          <Redirect to="/" />;
        </paid.Provider>
      }
    );
  };
  return (
    <div className="main-c">
      <div className="col-md-2">
        <a className="scroll logo" href="#wrapper">
          <img
            src="img/logo/ksc logo white_1.png"
            id="conclave-logo"
            alt=""
            width="80"
            height="116"
          />
        </a>
      </div>
      {redir()}
    </div>
  );
}

export default PaymentSuccess;
export {paid};
