import React from "react";
import kk from "../asset/tarora.jpg";
import $ from "jquery";
window.jQuery = $;
require("venobox");
function Gallery() {
  React.useEffect(() => {
    $(".background-img").each(function () {
      var l = $(this).children("img").attr("src");
      $(this)
        .css("background-image", 'url("' + l + '")')
        .css("background-position", "initial");
    });
  }, []);
  // ;
  return (
    <div>
      <section id="gallery" class="gallery pt-120">
        <div class="container">
          <div class="row">
            <div class="col-sm-8 col-sm-offset-2 mb-130 ">
              <svg
                version="1.1"
                id="Layer_12"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="40px"
                height="30px"
                viewBox="0 0 42 32"
                enableBackground="new 0 0 42 32"
                xmlSpace="preserve"
              >
                <linearGradient
                  id="SVGID_12_"
                  gradientUnits="userSpaceOnUse"
                  x1="0.9386"
                  y1="24.9189"
                  x2="42.6053"
                  y2="8.5439"
                >
                  <stop offset="0" style={{ stopColor: "#399BFF" }} />
                  <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                </linearGradient>
                <path
                  fill="url(#SVGID_12_)"
                  d="M39.5,6H32c-1.927,0-1.998-1.797-2-2V3c0-1.258-1.39-3-4-3h-9.969c-2.935,0-4,1.794-4,3v0.994
                           C12.03,4.076,11.983,6,10,6H2.5C1.121,6,0,7.122,0,8.5v18C0,27.878,1.121,29,2.5,29h6C8.776,29,9,28.776,9,28.5S8.776,28,8.5,28h-6
                           C1.673,28,1,27.327,1,26.5V14h8.003C8.359,15.54,8,17.229,8,19c0,7.168,5.832,13,13,13s13-5.832,13-13c0-1.771-0.359-3.46-1.003-5
                           H41v12.5c0,0.827-0.673,1.5-1.5,1.5h-7c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h7c1.379,0,2.5-1.122,2.5-2.5v-18
                           C42,7.122,40.879,6,39.5,6z M21,31c-6.617,0-12-5.383-12-12S14.383,7,21,7s12,5.383,12,12S27.617,31,21,31z M32.75,13
                           c-0.074,0-0.143,0.018-0.206,0.047C30.38,8.868,26.022,6,21,6s-9.38,2.868-11.544,7.047C9.393,13.018,9.324,13,9.25,13H1V8.5
                           C1,7.673,1.673,7,2.5,7H10c2.368,0,3.019-1.958,3.031-3V3c0-0.804,0.799-2,3-2H26c2.094,0,3,1.324,3,2v1c0,1.038,0.627,3,3,3h7.5
                           C40.327,7,41,7.673,41,8.5V13H32.75z"
                />
                <linearGradient
                  id="SVGID_13_"
                  gradientUnits="userSpaceOnUse"
                  x1="-5.5546"
                  y1="8.3968"
                  x2="36.1121"
                  y2="-7.9782"
                >
                  <stop offset="0" style={{ stopColor: "#399BFF" }} />
                  <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                </linearGradient>
                <path
                  fill="url(#SVGID_13_)"
                  d="M9.5,5C9.776,5,10,4.776,10,4.5v-1C10,2.673,9.327,2,8.5,2h-4C3.673,2,3,2.673,3,3.5v1
                           C3,4.776,3.224,5,3.5,5S4,4.776,4,4.5v-1C4,3.224,4.225,3,4.5,3h4C8.775,3,9,3.224,9,3.5v1C9,4.776,9.224,5,9.5,5z"
                />
                <linearGradient
                  id="SVGID_15_"
                  gradientUnits="userSpaceOnUse"
                  x1="1.6076"
                  y1="26.6212"
                  x2="43.2743"
                  y2="10.2462"
                >
                  <stop offset="0" style={{ stopColor: "#399BFF" }} />
                  <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                </linearGradient>
                <path
                  fill="url(#SVGID_15_)"
                  d="M21,10c-4.963,0-9,4.038-9,9s4.037,9,9,9s9-4.038,9-9S25.963,10,21,10z M21,27c-4.411,0-8-3.589-8-8
                           s3.589-8,8-8s8,3.589,8,8S25.411,27,21,27z"
                />
              </svg>
              <h1 class="title mt-10"> Memories From The Events </h1>
              <p class="title-lead mt-5">
                For more photo and video visit our social hubs.
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-sm-12 ">
              <ul class="block-gallery">
                <li class="col-sm-2 ">
                  <a href="img/other/12.jpg" class="venobox">
                    <div class="block-img">
                      <div class="background-img">
                        <img
                          alt=""
                          src="img/other/Event1.jpeg"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-sm-2 ">
                  <a href="img/other/13.jpg" class="venobox">
                    <div class="block-img">
                      <div class="background-img">
                        <img
                          alt=""
                          src="img/other/f (5).jpg"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-sm-2 ">
                  <a href="img/other/14.jpg" class="venobox">
                    <div class="block-img">
                      <div class="background-img">
                        <img
                          alt=""
                          src="img/other/f (6).jpg"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-sm-2 ">
                  <a href="img/15.jpg" class="venobox">
                    <div class="block-img">
                      <div class="background-img">
                        <img
                          alt=""
                          src="img/other/event.jpeg"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-sm-2 ">
                  <a href="img/other/16.jpg" class="venobox">
                    <div class="block-img">
                      <div class="background-img">
                        <img
                          alt=""
                          src="img/other/f (8)-min.JPG"
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </a>
                </li>
                <li class="col-sm-2 ">
                  <a href="img/other/17.jg" class="venobox">
                    <div class="block-img">
                      <div class="background-img">
                        <img alt="" src="img/other/f (9)-min.JPG" style={{ display: "none" }} />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gallery;
