import React, { useState, useEffect, createContext } from "react";
import "../App.css";
import $, { data } from "jquery";
import axios from "axios";
import { setUser } from "../actions";
import { SERVERURL } from "../util/Server";
import { connect } from "react-redux";
import swal from "@sweetalert/with-react";
window.jQuery = $;
require("venobox");
const loggedIn = createContext();
function NewLogin(props) {
  const [data, setData] = useState({ email: "", password: "" });
  //   const [info, setInfo] = useState("");
  const [forgot, setForgot] = useState(false);
  const toggleHandler = () => {
    setForgot(!forgot);
  };
  React.useEffect(() => {
    $(".background-img").each(function () {
      var l = $(this).children("img").attr("src");
      $(this)
        .css("background-image", 'url("' + l + '")')
        .css("background-position", "initial");
    });
  }, []);

  //   const [name, setname] = useState("");
  const postData = () => {
    // console.log(SERVERURL);
    // console.log(data);
    axios
      .post(`${SERVERURL}/auth/login`, data)
      .then((response) => {
        if (response.status === 200) {
          //verfication success
          <loggedIn.Provider value={true}></loggedIn.Provider>;
          const ndata = {
            email: data.email,
            name: response.data.name,
            token: response.data.token,
          };
          localStorage.setItem("token", response.data.token);
          props.setUser(ndata);

          setTimeout(function () {
            swal("Success", "Login Successful", "success");
          }, 50);
          setTimeout(function () {
            window.location.href = "/";
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          swal(
            "An Email has been sent to you.",
            "please verify your email",
            "warning"
          );
        } else if (err.response.status === 400) {
          swal(
            "Registration not done",
            "please register and then login",
            "warning"
          );
        } else if (err.response.status === 401) {
          swal("Password does not match", "", "warning");
        } else if (err.response.status === 404) {
          swal("email not found", "", "warning");
        } else {
          swal("server error");
        }
      });
  };

  const resetPassword = () => {
    axios
      .post(`${SERVERURL}/auth/reset`, { email: data.email })
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          swal(
            "Please check your email",
            "An email with reset link has been sent to you",
            "success"
          );
        } else {
          swal("Something went wrong", "please try again later", "warning");
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          swal(
            "Registration not done",
            "Please register and then login",
            "warning"
          );
        } else {
          swal("Something went wrong", "Please try again later", "warning");
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (forgot) {
      resetPassword();
    } else {
      postData();
    }
  };

  if (forgot) {
    return (
      <div>
        <section
          id="register"
          className="register pt-120 pb-120 overlay"
          style={{ height: "100vh" }}
        >
          <div className="background-img">
            <img
              src="img/other/Background-image.jpeg"
              alt=""
              style={{ height: "100vh" }}
            />
          </div>

          <div className="container">
            <div className="header_login">
              <img
                src="./img/logo/ksk_w.png"
                className="logo bounce-6 login_logo"
                alt="logo"
                style={{ width: "75px" }}
              />
            </div>
            <div className="row ">
              <div className="col-md-6 front-p login_form">
                <form className="registry-form form" method="post" action="#">
                  <h2 className="sub-title-1 mb-30">Forgot Password?</h2>

                  {/* <div className="col-sm-6">
                  <input
                    placeholder="Your Name"
                    value={name}
                    onChange={updatename}
                    id="name"
                    name="name"
                    type="text"
                    required
                  />
                </div> */}
                  <div
                    className="row align-items-center"
                    style={{ width: "90%" }}
                  >
                    <input
                      placeholder="Your Email"
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                      id="email"
                      name="email"
                      type="text"
                      className="col"
                      required
                      style={{ width: "90%" }}
                    />
                  </div>
                  {/* <div className="col-sm-6">
                  <input
                    placeholder="Phone number"
                    value={phone}
                    onChange={updatephone}
                    id="phone"
                    name="phone"
                    type="tel"
                  />
                </div> */}
                  {/* <div className="row align-items-center" 
                  style={{ width: "90%" }}>
                  <input
                    placeholder="Password"
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    id="password"
                    name="password"
                    type="password"
                    className="col"
                    
                  style={{ width: "90%" }}
                  />
                  </div>*/}
                  <div
                    className="row align-items-center"
                    onClick={handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <input
                      value="Submit"
                      className=" but submit"
                      type="submit"
                    />
                  </div>
                  <div
                    className="col-sm-12 forgot_password"
                    onClick={toggleHandler}
                  >
                    <a>Back to login</a>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      * Not yet registered?{" "}
                      <a href="/#register">Click here to register now.</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div>
        <section
          id="register"
          className="register pt-120 pb-120 overlay"
          style={{ height: "100vh" }}
        >
          <div className="background-img">
            <img
              src="img/other/Background-image.jpeg"
              alt=""
              style={{ height: "100vh" }}
            />
          </div>

          <div className="container">
            <div className="header_login">
              <img
                src="./img/logo/ksk_w.png"
                className="logo bounce-6 login_logo"
                alt="logo"
                style={{ width: "75px" }}
              />
            </div>
            <div className="row ">
              <div className="col-md-6 front-p login_form">
                <form className="registry-form form" method="post" action="#">
                  <h2 className="sub-title-1 mb-30">Welcome Back!</h2>

                  {/* <div className="col-sm-6">
                  <input
                    placeholder="Your Name"
                    value={name}
                    onChange={updatename}
                    id="name"
                    name="name"
                    type="text"
                    required
                  />
                </div> */}
                  <div
                    className="row align-items-center"
                    style={{ width: "90%" }}
                  >
                    <input
                      placeholder="Your Email"
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                      id="email"
                      name="email"
                      type="text"
                      className="col"
                      required
                      style={{ width: "90%" }}
                    />
                  </div>
                  {/* <div className="col-sm-6">
                  <input
                    placeholder="Phone number"
                    value={phone}
                    onChange={updatephone}
                    id="phone"
                    name="phone"
                    type="tel"
                  />
                </div> */}
                  <div
                    className="row align-items-center"
                    style={{ width: "90%" }}
                  >
                    <input
                      placeholder="Password"
                      value={data.password}
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      id="password"
                      name="password"
                      type="password"
                      className="col"
                      style={{ width: "90%" }}
                    />
                  </div>
                  <div
                    className="row align-items-center"
                    onClick={handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <input
                      value="Login"
                      className=" but submit"
                      type="submit"
                    />
                  </div>
                  <div
                    className="col-sm-12 forgot_password"
                    onClick={toggleHandler}
                  >
                    <a>Forgot password?</a>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      * Not yet registered?{" "}
                      <a href="/#register">Click here to register now.</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLogin);
export { loggedIn };
