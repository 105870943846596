import React from "react";
import "../App.css";
function Faq() {
  return (
    <div>
      <section id="faq" className="faq pt-120 pb-120 brd-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-100 text-center ">
              <h1 className="title">Frequently asked questions </h1>
              <p className="title-lead mt-20 mb-20">
                Some frequently asked questions for you.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2  ">
              <div className="block-faq mb-50">
                <h4 className="mb-10 gradient-text ">
                  Why should I participate in StartUp Konclave?
                </h4>
                <p>
                  If you have high aspirations, ideas but are stuck in traffic
                  looking for a way through the obstacles to find the right path
                  to your goals, you are in the right place. StartUp Konclave
                  2021 is the go-to place to kick-start your entrepreneurship
                  journey. Witness talks, lectures and interactions from the
                  best entrepreneurs hailing from different sectors of the
                  business spectrum. Join the herd and learn the tricks to
                  become a leader.{" "}
                </p>
              </div>
              <div className="block-faq mb-50">
                <h4 className="mb-10 gradient-text">
                  What is the procedure for participating in the StartUp
                  Konclave?
                </h4>
                <p>
                  You can register by clicking the above link, which will 
                  take you to a registration form. After you've completed all 
                  of the fields, you'll receive an email confirming that 
                  you've successfully registered.
                </p>
              </div>
              <div className="block-faq mb-50">
                <h4 className="mb-10 gradient-text">
                  How much is the registration fees?
                </h4> 
                <p>
                  The KIIT Startup Konclave 2021 is completely free to attend.
                  So, what are you waiting for? Head over to the registration 
                  link above and book a ticket for the event.
                </p>
              </div>
              {/* <div className="block-faq mb-50">
                <h4 className="mb-10 gradient-text">
                  What is the last date for registration?
                </h4>
                <p></p>
              </div> */}
              <div className="block-faq mb-50">
                <h4 className="mb-10 gradient-text">
                  What will I gain from this Konclave?
                </h4>
                <p>
                  KIIT Startup Konclave is a single-day episode packed with a 
                  fascinating bunch of events. You will get the opportunity to
                  hear about the implementation of various technologies and 
                  business models in some of the unicorn start-ups, access 
                  to exciting workshops, the rare opportunity to interact 
                  with influential personalities from the world of 
                  entrepreneurship, and above all, you will find yourself
                  way higher on the learning curve. Turn up in big numbers and 
                  in return, we promise a day you'll surely remember.{" "}
                </p>
              </div>
              <div className="block-faq mb-50">
                <h4 className="mb-10 gradient-text">
                 Will we get to interact with the esteemed speakers panel?
                </h4>
                <p>
                  Yes, surely. After each speaker session, we will be opening
                   our floor for you guys to ask your queries from the speakers. 
                   "Opportunity neglected can never be recovered". 
                   This is going to be one crazy opportunity you cannot think of missing. 
                   So, go register now and reserve your slot. The clock is ticking!{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2  text-center mt-50">
              <h2 className="sub-title-1">
                Didn’t find what your are looking for ?
              </h2>
              <p>
                <a
                  className="mb-10 gradient-text"
                  href="mailto:pcr@ecell.org.in "
                >
                  pcr@ecell.org.in
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
