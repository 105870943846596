import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddressForm({
  details,
  setDetails,
  setPayButtonState,
}) {
  const classes = useStyles();
  const [age, setAge] = React.useState("");

  const handlePaybutton = (event) => {
    if (details.roll.length > 0 && details.college.length > 0)
      setPayButtonState(false);
    else setPayButtonState(true);
  };

  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>
        S
      </Typography> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            // variant="filled"
            required
            id="collegeName"
            name="collegeName"
            label="Collage Name"
            fullWidth
            type="string"
            value={details.college}
            onChange={(e) => {
              setDetails({ ...details, college: e.target.value });
              handlePaybutton();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="roll"
            name="roll"
            label="Roll"
            fullWidth
            required
            autoComplete="family-name"
            value={details.roll}
            type="number"
            onChange={(e) => {
              setDetails({ ...details, roll: e.target.value });
              handlePaybutton();
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid> */}
        {/* <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          {/* <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
          /> */}
          <FormControl
            variant="filled"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={details.gender}
              onChange={(e) => {
                setDetails({ ...details, gender: e.target.value });
                handlePaybutton();
              }}
            >
              <MenuItem value={"M"}>Male</MenuItem>
              <MenuItem value={"F"}>Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
          /> */}
          <FormControl
            fullWidth
            variant="filled"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-label"> Select Event</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={details.type}
              onChange={(e) => setDetails({ ...details, type: e.target.value })}
            >
              <MenuItem value={1}>Startup Konclave </MenuItem>
              <MenuItem value={2}>Startup Konclave + IMPS</MenuItem>
              <MenuItem value={3}>Icamp + Startup Konclave </MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
          
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" name="saveAddress" value="yes" />
            }
            label="Use this address for payment details"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
