import React, { useState, useEffect } from "react";
import "../App.css";
import $ from "jquery";
import axios from "axios";
import { SERVERURL } from "../util/Server";
import swal from "@sweetalert/with-react";
import { useHistory } from "react-router";
import { Widget } from '@typeform/embed-react'


var qs = require("qs");
var assert = require("assert");
window.jQuery = $;
require("venobox");
function Register({ match }) {
  const [status, setStatus] = useState("");
  const [text, setText] = useState("");

  const history=useHistory();

  React.useEffect(() => {
    $(".background-img").each(function () {
      var l = $(this).children("img").attr("src");
      $(this)
        .css("background-image", 'url("' + l + '")')
        .css("background-position", "initial");
    });
    console.log(match.params.ref_id);
  }, []);

  const postData = {
    name: "",
    email: "",
    password: "",
    no: NaN,
    ref: match.params.ref_id,
    //match.params.ref_id
  };
  const [name, setname] = useState("");

  const updatename = (e) => {
    setname(e.target.value);
  };
  const [email, setemail] = useState("");
  const updateemail = (e) => {
    setemail(e.target.value);
  };
  const [phone, setphone] = useState("");
  const updatephone = (e) => {
    setphone(e.target.value);
  };
  const [password, setpassword] = useState("");
  const updatepassword = (e) => {
    setpassword(e.target.value);
  };
  const [ref_id, setref_id] = useState(postData.ref);
  const updaterefid = (e) => {
    setref_id(e.target.value);
  };
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    postData.name = name;
    postData.email = email;
    postData.no = phone;
    postData.password = password;
    postData.ref_id = ref_id;
    if (email === "" || name === "" || password === "") {
      swal("Please give valid input", "validation fail", "warning");
    } else if (password.length < 8) {
      swal("please enter a strong password", "validation failed", "warning");
    } else if (!validateEmail(email)) {
      swal("please enter a valid email", "validation failed", "warning");
    } else if (phone.length != 10) {
      swal("please enter a valid phone no", "validation failed", "warning");
    } else {
      axios
        .post(`${SERVERURL}/auth/signup`, postData)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            swal(
              "Registration successful",
              "Please verify your email",
              "success"
            );
            //alert("Registered successfully");
          } else if (response.status === 409) {
            swal("You have already registered", "Please Login", "warning");
          } else if (response.status === 500) {
            swal("Something went wrong", "Please try later", "error");
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            swal("Something went wrong", "Please try later", "error");
          } else if (err.response.status === 409) {
            swal("You have already registered", "Please Login", "warning");
          } else if (err.response.status === 500) {
            swal("Something went wrong", "Please try later", "error");
          }
        });
      setname("");
      setemail("");
      setphone("");
      setpassword("");
      setref_id("");
    }
  };

  return (
    <div>
      <section id="register" className="register pt-120 pb-120 overlay">
        <div className="background-img">
          <img src="./img/other/Background-image.jpeg" alt="" />
        </div>

        <div className="container" style={{width:'100%'}}>
          <div className="row" style={{width:'100%'}}>
            <div className="col-md-6 front-p" style={{width:'100%'}}>
              <form className="registry-form form" method="post" action="#" style={{width:'100%'}}>
                <h2 className="sub-title-1 mb-30">
                  Register for KIIT Startup Konclave 2021
                </h2>

                {/* <div className="col-sm-6">
                  <input
                    placeholder="Your Name"
                    value={name}
                    onChange={updatename}
                    id="name"
                    name="name"
                    type="text"
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    placeholder="Your Email"
                    value={email}
                    onChange={updateemail}
                    id="email"
                    name="email"
                    type="text"
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    placeholder="Phone number"
                    value={phone}
                    onChange={updatephone}
                    id="phone"
                    name="phone"
                    type="tel"
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    placeholder="Password"
                    value={password}
                    onChange={updatepassword}
                    id="password"
                    name="password"
                    type="password"
                  />
                </div> */}
                {/* <div className="col-sm-6">
                  <input
                    placeholder="Referral ID"
                    value={ref_id}
                    onChange={updaterefid}
                    id="refid"
                    name="refid"
                    type="text"
                  />
                </div>  */}
                {/* <div className="col-sm-12"><a href='https://n02n019x6xk.typeform.com/KSKregistration' target="_blank">
                  <input
                    value="Reserve your seat now!"
                    className=" but submit"
                    type="submit"
                  />
                  </a>
                </div> */}
                {/* <button className=" but submit"><a href="https://n02n019x6xk.typeform.com/KSKregistration" target="_blank">Book your seat now</a></button> */}
                {/* <div className="col-sm-12">
                <button data-tf-slider="DQveJzKk" dataTfWidth="550" style={{all:'unset','fontFamily':'Helvetica,Arial,sans-serif',display:'inline-block',maxWidth:'100%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',backgroundColor:'#0445AF',color:'#FFFFFF',fontSize:'20px',borderRadius:'25px',padding:'0 33px',fontWeight:'bold',height:'50px',cursor:'pointer',lineHeight:'50px',textAlign:'center',margin:'0',textDecoration:'none'}}>Launch me</button><script src="/embed.typeform.com/next/embed.js"></script>
                </div> */}


                    <Widget id="DQveJzKk" style={{ minWidth: '100%',height:'600px' }} className="my-form" />

                
               

                {/* <div className="col-sm-12">
                  <p>
                    * Already registered?{" "}
                    <a href="/login">Click here to Login now.</a>
                  </p>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
