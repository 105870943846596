import React, { useEffect, useState } from "react";
import "./App.css";
import Preloader from "./Components/Preloader";
import Hero from "./Components/Hero";
import About from "./Components/About";
import Speaker from "./Components/Speaker";
import Topics from "./Components/Topics";
import Schedule from "./Components/Schedule";
import Counter from "./Components/Counter";
import Tickets from "./Components/Tickets";
import Register from "./Components/Register";
import Sponsor from "./Components/Sponsor";
import Contact from "./Components/Contact";
import Faq from "./Components/Faq";
import Gallery from "./Components/Gallery";
import Footer from "./Components/Footer";
import Developer from "./Components/Developer";
import isAuthenticated from "./util/isAuthenticated";
import $ from "jquery";
import store from "./store";
import Linkedin from "./Components/Linkedin";
window.jQuery = $;

require("venobox");
function Main({ match }) {
  const [loadingState, setloadingState] = useState("true");

  useEffect(() => {
    setTimeout(() => {
      setloadingState("");
    }, 700);
  }, []);
  useEffect(() => {}, [store.getState().user.token]);

  if (loadingState) return <Preloader />;
  else if (isAuthenticated()) {
    return (
      <div className="wrapper">
        <Hero />
        <About />
        <Schedule />
        <Speaker />
        <Topics />
        
        <Counter />
        {/* <Tickets /> */}
        {/* <Register /> */}
        <Sponsor />
        <Contact />
        <Faq />
        <Gallery />
        <Developer/>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="wrapper">
        <Hero />
        <About />
        <Schedule />
        <Speaker />
        <Topics />
        <Linkedin/>
        <Counter />
        {/* <Tickets /> */}
        <Register match={match} />
        <Sponsor />
        <Contact />
        <Faq />
        <Gallery />
        
        <Footer />
        <Developer/>
      </div>
    );
  }
}

export default Main;
