import React from "react";
import "../App.css";
import { connect } from "react-redux";
import { setUser } from "../actions";
import swal from "@sweetalert/with-react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
window.jQuery = $;
require("jquery-smooth-scroll");
function Hero(props) {
  React.useEffect(() => {
    var l = $(".mobile-but");
    var t = $(".main-nav ul");
    t.height();
    $(l).on("click", function () {
      return (
        $(".toggle-mobile-but").toggleClass("active"),
        t.slideToggle(),
        $(".main-nav li a").addClass("mobile"),
        !1
      );
    });
    $(window).resize(function () {
      return (
        $(window).width() > 320 &&
        t.is(":hidden") &&
        (t.removeAttr("style"), $(".main-nav li a").removeClass("mobile"))
      );
    });
    $(".main-nav li a").on("click", function () {
      return (
        $(this).hasClass("mobile") &&
        (t.slideToggle(), $(".toggle-mobile-but").toggleClass("active"))
      );
    });

    $("a.scroll").smoothScroll({ speed: 800, offset: -50 });
  }, []);
  const { token } = props;
  const isAuthenticated = () => {
    console.log(token);
    if (token && token !== "null") {
      return true;
    } else {
      return false;
    }
  };
  const hanndleLogout = () => {
    localStorage.setItem("token", null);
    props.setUser({ email: "", name: "", token: "" });
    swal("Logout Successful", "success");
  };
  const handleLoginChange = () => {
    if (isAuthenticated()) {
      localStorage.setItem("token", null);
      props.setUser({ email: "", name: "", token: "" });
      swal("Success", "Logout Successful", "success");
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div>
      <section className="hero overlay">
        <div className="main-slider slider">
          <ul className="slides" id="slides">
            <li>
              <div className="background-image zoominheader">
                <div className="zoomoutheader">
                  <img
                    src="img/other/bg2.jpeg"
                    alt=""
                    height="20px"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <header className="header">
          <div className="container ">
            <div className="row">
              <div className="col-md-2">
                <a className="scroll logo" href="#slides">
                  <img src="img/logo/Combined 1.png" class="ecell" alt="" />
                </a>
              </div>
              <div className="col-md-10 text-right">
                <nav className="main-nav">
                  <div className="toggle-mobile-but">
                    <a href="#" className="mobile-but">
                      <div className="lines"></div>
                    </a>
                  </div>
                  <ul>
                    <li>
                      <a className="scroll" href="#slides">
                        Home
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#about">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#speakers">
                        Speakers
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#topics">
                        Topics
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#schedule">
                        Schedule
                      </a>
                    </li>
                    {/* <li>
                      <a className="scroll" href="#tickets">
                        Tickets
                      </a>
                    </li> */}
                    <li>
                      <a className="scroll" href="#location">
                        Location
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#faq">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#gallery">
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a className="scroll" href="#developer">
                        Developers
                      </a>
                    </li>
                    <li>
                      {isAuthenticated() ? (
                        <button
                          href="#"
                          onClick={handleLoginChange}
                          // target="_blank"
                          className="scroll but"
                          style={{ padding: " 10px 22px" }}
                        >
                          Logout
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <div className="inner-hero fade-out">
          <div className="container hero-content">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h3 className="mb-10">
                  Kalinga Institute of Industrial Technology presents
                </h3>
                <h1 className="large mb-10">STARTUP KONCLAVE 2021</h1>
                <p className="uppercase ">14th November 2021 </p>
                {isAuthenticated() ? (
                  <a href="/checkout" className="but scroll ">
                    {" "}
                    Get Tickets
                  </a>
                ) : (
                  <div>
                    <a href="#register" className="but scroll ">
                      {" "}
                      Register Here
                    </a>
                    {/* <a
                      href="/login"
                      className="but scroll"
                      style={{
                        marginLeft: "10px",
                        background: "none",
                        border: "1px solid #fff",
                      }}
                    >
                      {" "}
                      Login Here
                    </a> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.user.token,
});
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
