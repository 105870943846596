import React from "react";
import "../App.css";
function About() {
  return (
    <div>
      <section id="about" className="about pt-120 pb-120 brd-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
              <h1 className="title">KIIT Startup Konclave 2021</h1>
              <p className="title-lead mt-20">
              KIIT Startup Konclave stands for bringing together people from all around KIIT University and India who are interested in starting their own business and are geared up about showcasing their skills and strengths to the world. This will aid in fostering an entrepreneurship culture among participants as well as their professional development. The planned date for KIIT Startup Konclave is November 14, 2021. This procedure will maintain the spirit of entrepreneurship.
              The main goal of the KIIT Start-Up Konclave is to provide a platform for the start-up community to express their views, find potential buyers and mentors, and receive ample resources to help their businesses succeed. We hope to foster a culture of productivity and innovation by hosting multiple workshops, panel discussions, and other events that will provide attendees with plenty of networking possibilities in order to grow and expand their businesses.

              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="block-info-1">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="35px"
                  height="25px"
                  viewBox="0 0 42 32"
                  enable-background="new 0 0 42 32"
                  xmlSpace="preserve"
                >
                  <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="5.1983"
                    y1="28.1187"
                    x2="43.4067"
                    y2="11.702"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_1_)"
                    d="M38,30.5v-19c0-0.276-0.224-0.5-0.5-0.5S37,11.224,37,11.5v19c0,0.276-0.224,0.5-0.5,0.5h-31
                              C5.224,31,5,30.776,5,30.5v-19C5,11.224,4.776,11,4.5,11S4,11.224,4,11.5v19C4,31.327,4.673,32,5.5,32h31
                              C37.327,32,38,31.327,38,30.5z"
                  />
                  <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1="4.7162"
                    y1="26.9965"
                    x2="42.9245"
                    y2="10.5799"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_2_)"
                    d="M8.5,23C8.224,23,8,23.224,8,23.5S8.224,24,8.5,24H10v3.5c0,0.276,0.224,0.5,0.5,0.5
                              s0.5-0.224,0.5-0.5V24h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24
                              h6v3.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V24h1.5c0.276,0,0.5-0.224,0.5-0.5S33.776,23,33.5,23H32v-5h1.5
                              c0.276,0,0.5-0.224,0.5-0.5S33.776,17,33.5,17H32v-4.5c0-0.276-0.224-0.5-0.5-0.5S31,12.224,31,12.5V17h-6v-4.5
                              c0-0.276-0.224-0.5-0.5-0.5S24,12.224,24,12.5V17h-6v-4.5c0-0.276-0.224-0.5-0.5-0.5S17,12.224,17,12.5V17h-6v-4.5
                              c0-0.276-0.224-0.5-0.5-0.5S10,12.224,10,12.5V17H8.5C8.224,17,8,17.224,8,17.5S8.224,18,8.5,18H10v5H8.5z M31,18v5h-6v-5H31z
                              M24,18v5h-6v-5H24z M11,18h6v5h-6V18z"
                  />
                  <linearGradient
                    id="SVGID_3_"
                    gradientUnits="userSpaceOnUse"
                    x1="-0.4811"
                    y1="14.9003"
                    x2="37.7272"
                    y2="-1.5164"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_3_)"
                    d="M32.5,3h4.25C36.837,3,37,3,37,3.5V8H5V3.5C5,3.224,5.224,3,5.5,3h4C9.776,3,10,2.776,10,2.5
                              S9.776,2,9.5,2h-4C4.673,2,4,2.673,4,3.5v5C4,8.776,4.224,9,4.5,9h33C37.776,9,38,8.776,38,8.5v-5C38,2.394,37.354,2,36.75,2H32.5
                              C32.224,2,32,2.224,32,2.5S32.224,3,32.5,3z"
                  />
                  <linearGradient
                    id="SVGID_4_"
                    gradientUnits="userSpaceOnUse"
                    x1="-1.6311"
                    y1="12.2237"
                    x2="36.5772"
                    y2="-4.1929"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_4_)"
                    d="M26.5,3C26.776,3,27,2.776,27,2.5S26.776,2,26.5,2h-11C15.224,2,15,2.224,15,2.5S15.224,3,15.5,3
                              H26.5z"
                  />
                  <linearGradient
                    id="SVGID_5_"
                    gradientUnits="userSpaceOnUse"
                    x1="-2.9557"
                    y1="9.1407"
                    x2="35.2526"
                    y2="-7.2759"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_5_)"
                    d="M13,4.5v-4C13,0.224,12.776,0,12.5,0S12,0.224,12,0.5v4C12,4.776,12.224,5,12.5,5S13,4.776,13,4.5z"
                  />
                  <linearGradient
                    id="SVGID_6_"
                    gradientUnits="userSpaceOnUse"
                    x1="-0.3065"
                    y1="15.3067"
                    x2="37.9019"
                    y2="-1.11"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_6_)"
                    d="M29.5,5C29.776,5,30,4.776,30,4.5v-4C30,0.224,29.776,0,29.5,0S29,0.224,29,0.5v4
                              C29,4.776,29.224,5,29.5,5z"
                  />
                </svg>
                <p>
                  <strong>DATE</strong>
                  <span>14th November 2021</span>
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="block-info-1">
                <svg
                  version="1.1"
                  id="Layer_7"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="35px"
                  height="25px"
                  viewBox="0 0 42 32"
                  enable-background="new 0 0 42 32"
                  xmlSpace="preserve"
                >
                  <linearGradient
                    id="SVGID_7_"
                    gradientUnits="userSpaceOnUse"
                    x1="4.511"
                    y1="11.8158"
                    x2="41.3229"
                    y2="27.6695"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_7_)"
                    d="M10.239,31.926c0.009,0.006,0.021,0.003,0.03,0.009C10.341,31.973,10.418,32,10.499,32
                              c0.044,0,0.088-0.006,0.132-0.018l10.868-2.966l10.868,2.966C32.411,31.994,32.455,32,32.499,32c0.082,0,0.158-0.027,0.23-0.065
                              c0.01-0.005,0.021-0.003,0.03-0.009l9-5.5c0.191-0.117,0.281-0.348,0.22-0.563l-4.984-17.5c-0.041-0.147-0.148-0.267-0.29-0.326
                              c-0.142-0.057-0.301-0.048-0.436,0.026l-4.962,2.784c-0.24,0.135-0.326,0.44-0.191,0.681c0.135,0.242,0.439,0.327,0.682,0.191
                              l4.409-2.475l4.707,16.526l-8.015,4.898l-1.904-15.231c-0.034-0.275-0.293-0.466-0.559-0.434c-0.273,0.034-0.468,0.284-0.434,0.558
                              l1.907,15.259L22,28.115v-2.73c0-0.276-0.224-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v2.73l-9.911,2.705l1.907-15.259
                              c0.034-0.274-0.16-0.524-0.434-0.558c-0.272-0.032-0.524,0.159-0.559,0.434l-1.904,15.231L2.084,25.77L6.791,9.244l4.409,2.475
                              c0.242,0.134,0.546,0.049,0.682-0.191c0.135-0.241,0.049-0.545-0.191-0.681L6.729,8.063C6.595,7.988,6.436,7.979,6.293,8.037
                              c-0.142,0.059-0.249,0.178-0.29,0.326l-4.984,17.5c-0.062,0.216,0.028,0.446,0.22,0.563L10.239,31.926z"
                  />
                  <linearGradient
                    id="SVGID_8_"
                    gradientUnits="userSpaceOnUse"
                    x1="12.6241"
                    y1="7.5582"
                    x2="28.5468"
                    y2="14.4156"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_8_)"
                    d="M21.161,23.367c0.096,0.088,0.217,0.132,0.339,0.132c0.12,0,0.24-0.043,0.336-0.129
                              C22.169,23.067,30,15.882,30,8.499c0-4.767-3.733-8.5-8.5-8.5S13,3.732,13,8.499C13,15.753,20.828,23.059,21.161,23.367z
                              M21.5,0.999c4.275,0,7.5,3.224,7.5,7.5c0,6.097-5.993,12.337-7.497,13.807C20.002,20.819,14,14.497,14,8.499
                              C14,4.223,17.225,0.999,21.5,0.999z"
                  />
                  <linearGradient
                    id="SVGID_9_"
                    gradientUnits="userSpaceOnUse"
                    x1="17.3671"
                    y1="6.7191"
                    x2="25.6329"
                    y2="10.2789"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_9_)"
                    d="M26,8.499c0-2.481-2.019-4.5-4.5-4.5S17,6.018,17,8.499s2.019,4.5,4.5,4.5S26,10.98,26,8.499z
                              M21.5,11.999c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S23.43,11.999,21.5,11.999z"
                  />
                </svg>
                <p>
                  <strong>LOCATION</strong>
                  <span>Virtual</span>
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="block-info-1">
                <svg
                  version="1.1"
                  id="Layer_10"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="35px"
                  height="25px"
                  viewBox="0 0 42 32"
                  enable-background="new 0 0 42 32"
                  xmlSpace="preserve"
                >
                  <linearGradient
                    id="SVGID_10_"
                    gradientUnits="userSpaceOnUse"
                    x1="1.9027"
                    y1="28.6566"
                    x2="55.9027"
                    y2="10.9483"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_10_)"
                    d="M40.498,0c-0.276,0-0.5,0.224-0.5,0.5v31c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-31
                              C40.998,0.224,40.774,0,40.498,0z"
                  />
                  <linearGradient
                    id="SVGID_14_"
                    gradientUnits="userSpaceOnUse"
                    x1="1.1102"
                    y1="26.2399"
                    x2="55.1102"
                    y2="8.5316"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_10_)"
                    d="M38.717,1.204c-0.17-0.083-0.376-0.062-0.526,0.055l-0.565,0.445C34.648,4.059,29.667,8,19.5,8H5.631
                              C4.18,8,3,9.2,3,10.674v10.714C3,22.828,4.18,24,5.631,24h3.394c0.088,1.125,0.502,3.794,2.454,5.761
                              C12.953,31.247,14.979,32,17.5,32c0.276,0,0.5-0.224,0.5-0.5S17.776,31,17.5,31c-2.242,0-4.026-0.652-5.306-1.938
                              c-1.668-1.677-2.067-4.025-2.163-5.062h2.995c0.085,0.682,0.36,1.881,1.274,2.802C15.089,27.597,16.166,28,17.5,28
                              c0.276,0,0.5-0.224,0.5-0.5S17.776,27,17.5,27c-1.055,0-1.891-0.302-2.484-0.896c-0.657-0.659-0.894-1.546-0.981-2.104h5.396
                              c10.216,0,15.237,3.963,18.237,6.331l0.522,0.411c0.089,0.07,0.198,0.105,0.307,0.105c0.075,0,0.15-0.017,0.219-0.051
                              c0.172-0.084,0.281-0.258,0.281-0.449V1.653C38.998,1.462,38.889,1.288,38.717,1.204z M4,21.388V10.674C4,9.751,4.731,9,5.631,9H9
                              v14H5.631C4.716,23,4,22.292,4,21.388z M37.998,29.317C34.864,26.851,29.672,23,19.432,23h-5.797
                              c-0.047-0.015-0.095-0.03-0.148-0.031c-0.001,0-0.001,0-0.002,0c-0.054,0-0.105,0.015-0.154,0.031H10V9h9.5
                              c10.237,0,15.392-3.864,18.498-6.316V29.317z"
                  />
                  <linearGradient
                    id="SVGID_11_"
                    gradientUnits="userSpaceOnUse"
                    x1="-1.8839"
                    y1="17.1097"
                    x2="52.1161"
                    y2="-0.5987"
                  >
                    <stop offset="0" style={{ stopColor: "#399BFF" }} />
                    <stop offset="1" style={{ stopColor: "#EE71F9" }} />
                  </linearGradient>
                  <path
                    fill="url(#SVGID_11_)"
                    d="M1.5,21.857c0.276,0,0.5-0.224,0.5-0.5V10.643c0-0.276-0.224-0.5-0.5-0.5S1,10.367,1,10.643v10.714
                              C1,21.633,1.224,21.857,1.5,21.857z"
                  />
                </svg>
                <p>
                  <strong>SPEAKERS</strong>
                  <span>35+ Professional Speakers </span>
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 text-right">
              <div className="block-info-1">
                <a href="#register" className="scroll but">
                  {" "}
                  Book your seat
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
