import React from 'react'
import '../App.css'
function Sponsor() {
    return (
        <div>
           <section className="sponser pt-100 pb-100">
            
            <div className="container">
               <div className="row">
                  <div className="col-sm-8 col-sm-offset-2 mb-50 text-center ">
                     <h1 className="title"> Partners </h1>
                     <p className="title-lead mt-10 mb-20">For further info about sponsoring feel free to get in touch with us</p>
                  </div>
               </div>
            </div>
            <div className="container">
               <div className="row vertical-align">
                  <div className="col-md-6 col-sm-6">
                     <p style = {{fontSize: "20px"}}>Proudly presenting our </p>
                     <p style = {{fontSize: "30px", marginTop: "10px"}}>Technology Partner</p><br/><br/>
                     <img src = "img/logo/acer.png" style = {{height: "150px", width: "250px"}}/>  
                  </div>
                  <div className="col-md-6 col-sm-6">
                     <div className="block-video">
                        {/* <iframe width="1349" height="498" src="https://youtu.be/Z0DemkvYNU8" frameborder="0"
                           allow="accelerometer; autoplay=1;color=#ffffff; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                           allowfullscreen></iframe> */}
                        <iframe width="768" height="432" src="https://www.youtube.com/embed/jOQXtwZRf1c" title="YouTube video player" 
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen></iframe>
                     </div>
                  </div>
               </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="container">
               
               <div className="row">
                  <div className="col-sm-12  col-sm-push-2 text-center">
                     <div className="col-md-2 col-sm-2 block-sponsor">
                        <div className="image">
                           <img src="img/logo/Badelogin.png" alt="" style = {{height: "120px", width: "280px"}}/>
                        </div>
                     </div>
                     <div className="col-md-2 col-sm-2 block-sponsor">
                        <div className="image">
                           <img src="img/logo/GH.png" alt="" style = {{height: "120px", width: "460px"}}/>
                        </div>
                     </div>
                     <div className="col-md-2 col-sm-2 block-sponsor">
                        <div className="image">
                           <img src="img/logo/Givemycertificate.png" alt="loading" style = {{height: "120px", width: "120px"}}/>
                        </div>
                     </div>
                     <div className="col-md-2 col-sm-2 block-sponsor">
                        <div className="image">
                           <img src="img/logo/GrabOn.png" alt="" style = {{height: "170px", width: "220px"}}/>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row" style = {{marginTop: "60px"}}>
                  <div className="col-sm-12  col-sm-push-2 text-center">
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Lamron.png" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/LWT.png" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Startupnewsfyi.png" alt="loading" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        {/* <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Foxmula.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div> */}
                  </div>
               </div>
               {/* <div className="row" style = {{marginTop: "60px"}}>
                  <div className="col-sm-12  col-sm-push-2 text-center">
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Ace Blend.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Brick and Clay.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Colour Drama.jpeg" alt="loading" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Forr.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                  </div>
               </div> */}
               {/* <div className="row" style = {{marginTop: "60px"}}>
                  <div className="col-sm-12  col-sm-push-2 text-center">
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Give My Certificate.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Grab On.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Squash Code.jpeg" alt="loading" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                        <div className="col-md-2 col-sm-2 block-sponsor">
                           <div className="image">
                              <img src="img/logo/Budapest.jpeg" alt="" style = {{height: "120px", width: "120px"}}/>
                           </div>
                        </div>
                  </div>
               </div> */}
            </div>
            
         </section> 
        </div>
    )
}

export default Sponsor
