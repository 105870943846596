import React from 'react'
//import '../Signup.css'
function Signup() {
    return (
        <div className="sign-up">
            <div className="col-md-2">
        <a className="scroll logo" href="#wrapper">
           <img src="img/logo/ksc logo white_1.png" alt="" width = "60" height = "87"/>
        </a>
    </div>
  <div className="Form">
    <form>
      <p className="login">FORGOT PASSWORD</p><br/>
      <label> </label>
      <input type="email" id="username" placeholder="Email"/> <br/>
      <input type="text" id="password" placeholder="OTP"/> <br/><br/>
      <input type="submit" id="submit" value="Submit"/><br/>
    </form>
  </div>
        </div>
    )
}

export default Signup
