import React from 'react'
import '../App.css'
function Preloader() {
    return (
        <div>
            <div class="loader">
         
         <div class="loader-inner">
            <svg width="120" height="220" viewbox="0 0 100 100" class="loading-spinner" version="1.1" xmlns="http://www.w3.org/2000/svg">
               <circle class="spinner" cx="50" cy="50" r="21" fill="#111111" stroke-width="1.5"/>
            </svg>
         </div>
         
      </div>
        </div>
    )
}

export default Preloader
