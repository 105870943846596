import React,{useRef, useState,useEffect} from 'react'
//import { CountdownCircleTimer } from "react-countdown-circle-timer";
import '../App.css'

function Counter() {
   const [day,setDay]=useState('00');
   const [hour,setHour]=useState('00');
   const [min,setMin]=useState('00');
   const [sec,setSec]=useState('00');

   let interval=useRef();

   const startTimer=()=>{
      const countdownDate=new Date('November 14 2021 00:00:00').getTime();
      interval=setInterval(()=>{
         const now=new Date().getTime();
         const distance=countdownDate-now;
         const days=Math.floor(distance/(1000*60*60*24));
         const hours=Math.floor(distance%(1000*60*60*24)/(1000*60*60));
         const minutes=Math.floor((distance%(1000*60*60))/(1000*60));
         const seconds=Math.floor((distance%(1000*60))/1000);
         if(distance<0){
            //stop timer
            clearInterval(interval.current);
         }
         else{
            setDay(days);
            setHour(hours);
            setMin(minutes);
            setSec(seconds);
         }
      },1000)
   };

   useEffect(()=>{
      startTimer();
      return ()=>{
         clearInterval(interval.current);
      }
   });
    return (
        <div>
           <section className="counter pt-120 pb-120 overlay">
            <div className="background-img" >
               <img src="img/other/6.jpg" alt=""/>
            </div>
            
            <div className="container">
               
               <div className="row">
                  <div className="col-sm-12  text-center  front-p">
                     <h1 className="title">Time left until the konclave start </h1>
                     <p className="title-lead mt-10 mb-20">14th November 2021 with over 35+ sessions  </p>
                     <span className="countdown gradient-text timer">
                        {day<10?"0"+day:day}
                        <span className="timename"> days </span>:{hour<10?"0"+hour:hour}<span className="timename">h</span>:{min<10?"0"+min:min}<span className="timename"> m</span>:{sec<10?"0"+sec:sec}<span className="timename"> s</span>
                     </span>
                  </div>
               </div>
               
            </div>
            
         </section> 
        </div>
    )
}

export default Counter
