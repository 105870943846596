import React from "react";
import "../App.css";
function Contact() {
  return (
    <div>
      <section id="location" className="location pt-200 pb-200 bg-dark">
        <div className="block-map ">
          <div id="map" className="map">
            <div className="mapouter">
              <div class="gmap_canvas">
                <iframe
                  width="600"
                  height="500"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=kiit%20ecell&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://youtube-embed-code.com"></a>
                <br />
                <a href="https://www.embedgooglemap.net">
                  embedded maps google
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container block-contact">
          <div className="row">
            <div className="col-md-5 col-md-offset-7 col-sm-5 col-sm-offset-7">
              <h1 className="title">Contact Info </h1>
              <p className="title-lead mt-10 mb-50">
                Dear guests, you are most welcomed to join us at Startup
                Konclave. Have a pleasant experience.{" "}
              </p>
            </div>
            <div className="col-md-5 col-md-offset-7 col-sm-5 col-sm-offset-7">
              <div className="block-info-3">
                <p  >
                  Cyrus Bhandari
                  <br />
                  Executive, KIIT E-Cell
                  <br />
                  +91 7042649334
                  <br />
                  <br />
                  Yuvika Singh
                  <br />
                  Executive, KIIT E-Cell
                  <br />
                  +91 8755471249
                  <br />
                 
                  Email :{" "}
                  <a href="mailto:pcr@ecell.org.in" className="gradient-text">
                    pcr@ecell.org.in
                  </a>
                  <br/>
                  <br/>
                  <p>For Technical Queries</p>
                 
                  Aarushi Shanker
                  <br />
                  POC,Tech, KIIT E-Cell
                  <br />
                  +91 6287168998
                  <br />
                  Email :{" "}
                  <a href="mailto:aarushi.kiitecell@gmail.com" className="gradient-text">
                    aarushi.kiitecell@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
