import React from "react";
import "../App.css";

const SPEAKERS_DATA = [
  {
    name: "SHEETAL RAMKUMAR",
    company: "White Globe Group",
    designation: "Founder & CEO",
    img: "img/SpeakerPic/SheetalRamkumar_Whiteglobe_Bizznari.jpg",
  },
  {
    name: "MONALISHA THAKUR",
    company: "Tummoc",
    designation: "Co-Founder & CMO",
    img: "img/SpeakerPic/MonalishaThakur_Tummoc_Bizznari.jpg",
  },
  {
    name: "BASUDHA SHRIVASTAVA",
    company:
      "ExpressEarth Digital Services Pvt. Ltd & Empfly Services Pvt. Ltd.",
    designation: "Founder & Director",
    img: "img/SpeakerPic/BasudhaShrivastava_ExpressEarth_Empfly_Bizznari11.jpg",
  },
  {
    name: "SURAJ VERNEKAR",
    company: "Aegeus Technologies",
    designation: "Co-Founder & CEO",
    img: "img/SpeakerPic/SurajVernaker_AegeusTechnologies_Robozone.jpeg",
  },
  {
    name: "NITIN GUPTA",
    company: "FlytBase",
    designation: "Founder & CEO",
    img: "img/SpeakerPic/NitinGupta_FlytBase_Robozone.png",
  },
  {
    name: "AJINKYA BHAGWAT",
    company: "CRED",
    designation: "Design & Product",
    img: "img/SpeakerPic/AjinkyaBhagwat_CRED_Hawkeye.png",
  },
  {
    name: "ONKAR SONAWANE",
    company: "AlanceSec",
    designation: "Founder & CEO",
    img: "img/SpeakerPic/OnkarSonawane_Alancesec_Websec.jpg",
  },
  {
    name: "DR. LOPA MUDRAA BASUU",
    company: "APAC RESEARCH ADVISORY",
    designation: "CyberSecurity Leader & Advisor",
    img: "img/SpeakerPic/Dr.LopamudraaBasuu_Websec.jpg",
  },
  {
    name: "SATHISH ASHWIN",
    company: "GAIN Credit",
    designation: "Enterprise Security Architect",
    img: "img/SpeakerPic/SatishAshwin1.png",
  },
  {
    name: "NIKHIL TRIPATHI",
    company: "Bijak",
    designation: "Co-Founder",
    img: "img/SpeakerPic/NikhilTripathi_Bijak_Agrotech(KSBT).jpg",
  },
  {
    name: "ANANDA PRAKASH VERMA",
    company: "FASAL",
    designation: "Founder",
    img: "img/SpeakerPic/AnandaPrakashVerma_Fasal_Agrotech.jpg",
  },
  {
    name: "RADDHIKA SINGH",
    company: "Intellectual Property and Corporate Law Attorneys",
    designation: "Managing Partner, Aletheiaa Legal",
    img: "img/SpeakerPic/RaddhikaSingh_Lawyered_Advocacy.jpg",
  },
  {
    name: "VIDYUT BEDEKAR",
    company: "TLA",
    designation: "Practice Head",
    img: "img/SpeakerPic/VidyutBedekar_Lawyered_Advocacy.jpg",
  },
  {
    name: "HIMANSHU GUPTA",
    company: "Lawyered",
    designation: "CEO & Co-Founder",
    img: "img/SpeakerPic/HimanshuGupta_Lawyered_Advocacy.jpg",
  },
  {
    name: "PRADEEP BHUPATIRAJU",
    company: " Altacit Global",
    designation: "Head Of Patent Department",
    img: "img/SpeakerPic/PradeepBhupatiraju_Lawyered_Advocacy.jpeg",
  },
];

function Speaker() {
  return (
    <div>
      <section id="speakers" className="speakers pt-120 brd-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
              <h1 className="title">
                 Speakers- KIIT Startup Konclave 2021
              </h1>
            </div>
          </div>
        </div>

        <div className="block-content">
          {SPEAKERS_DATA.map((speaker) => {
            return (
              <div key={speaker.name} className="col-sm-3 pd-0">
                <div className="block-speaker .zoomIn">
                  <div className="block-img overlay soft">
                    <div className="background-imgeeee">
                      <img src={speaker.img} alt="" />
                    </div>
                    <div className="block-info-2">
                      <p>
                        <strong>{speaker.name}</strong>
                        <br />
                        <strong>
                          <em>{speaker.company}</em>
                        </strong>
                        <span>{speaker.designation}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="col-sm-offset-0 text-center"
          style={{ marginTop: "100px" }}
        >
          <img
            className="col-sm-4 "
            alt=""
            src="img/other/contentify.jpeg"
            // style={{ display: "none" }}
            style={{ marginTop: "10px" }}
          />
          <img
            className="col-sm-4 "
            alt=""
            src="img/other/ankitPanth.jpeg"
            // style={{ display: "none" }}
            style={{ marginTop: "10px" }}
          />
          <img
            className="col-sm-4 "
            alt=""
            src="img/other/vipul.jpeg"
            style={{ marginTop: "10px" }}
            // style={{ display: "none" }}
          />
        </div>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
              <h1 className="title" style={{ marginTop: "80px" }}>
                Previous Investors
              </h1>
            </div>
          </div>
        </div>
        <div className="block-content">
          <div className="col-sm-4 pd-0">
            <div className="block-speaker .zoomIn">
              <div className="block-img overlay soft">
                <div className="background-imge">
                  <img
                    src="img/other/Mayuresh Raut.png"
                    alt=""
                    style={{ height: "395px", width: "395px" }}
                  />
                </div>
                <div className="block-info-2">
                  <p>
                    <strong>Mayuresh Raut</strong>
                    <br />
                    <span>Managing Partner at SEA Fund</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 pd-0">
            <div className="block-speaker .zoomIn">
              <div className="block-img overlay soft">
                <div className="background-imge">
                  <img
                    src="img/other/Anil Joshi.jpeg"
                    alt=""
                    style={{ height: "395px", width: "395px" }}
                  />
                </div>
                <div className="block-info-2">
                  <p>
                    <strong>Anil Joshi</strong>
                    <br />
                    <span>Managing Partner at Unicorn India Ventures</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 pd-0">
            <div className="block-speaker .zoomIn">
              <div className="block-img overlay soft">
                <div className="background-imge">
                  <img
                    src="img/other/Hari Balasubramanian.jpeg"
                    alt=""
                    style={{ height: "395px", width: "395px" }}
                  />
                </div>
                <div className="block-info-2">
                  <p>
                    <strong>Hari Balasubramanian</strong>
                    <br />
                    <span>Angel Investor and Mentor</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Speaker;
