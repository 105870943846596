import React from 'react'
import {NavLink} from 'react-router-dom'
//import '../Login.css'
import './Signup'
import Signup from './Signup'
function Login() {
    return (
        <div className="login-main">
            <div className="col-md-2">
        <a className="scroll logo" href="#wrapper">
           <img src="img/logo/ksc logo white_1.png" alt="" width = "60" height = "87"/>
        </a>
    </div>
  <div className="Form">
    <form>
      <p className="login"> LOGIN </p>
      
      <input type="text" id="username" placeholder="Username"/> <br/>
      <input type="password" id="password" placeholder="Password"/> <br/><br/>
      <input type="submit" id="submit" value="Submit"/><br/><br/><br/><br/>
      <NavLink exact to='/forgotpassword'>Forgot password?</NavLink>
    </form>
  </div>
  </div>   
    )
}

export default Login
