import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { SERVERURL } from "../util/Server";
import { connect } from "react-redux";
import { setUser } from "../actions";
import axios from "axios";
import swal from "@sweetalert/with-react";
// import styleCss from "./css/exclude.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://ecell.org.in">
        KIIT ECELL
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["details"];

function getStepContent(step, details, setDetails, setPayButtonState) {
  switch (step) {
    // case 0:
    //   return <AddressForm />;
    case 0:
      return (
        <AddressForm
          details={details}
          setDetails={setDetails}
          setPayButtonState={setPayButtonState}
        />
      );
    // case 1:
    //   return <Review />;
    default:
      throw new Error("Unknown step");
  }
}

const Checkout = (props) => {
  const [details, SetDetails] = React.useState({
    roll: "",
    type: 3,
    gender: "F",
    college: "",
  });
  const [payButtonState, setPayButtonState] = React.useState(true);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const handlePayment = () => {
    // window.location.href = "google.com";
    // window.location.replace("http://www.w3schools.com");
    const authToken = props.authToken;
    if (authToken != "undefined") {
      const data = {
        type: 1,
      };

      axios
        .post(`${SERVERURL}/auth/payment`, details, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
        .then((res) => {
          if (res.status === 200) {
            window.location.replace(res.data.url);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            swal("You have been logout", "please login", "warning");
            window.location.href = "https://ksk.ecell.org.in/login";
          } else if (err.response.status === 402) {
            swal(
              "ICAMP IS ONLY FOR KIIT STUDENT",
              "If you are a Kiit student register with KIIT email ",
              "warning"
            );
            window.location.href("https://ksk.ecell.org.in/login");
          } else {
            swal("Something went wrong", "server error", "error");
          }
        });
      console.log(props.authToken);
      console.log(details);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            KIIT ECELL
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h6" align="center">
            CheckOut Details
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>{handlePayment()}</React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(
                  activeStep,
                  details,
                  SetDetails,
                  setPayButtonState
                )}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={payButtonState}
                  >
                    {activeStep === steps.length - 1 ? "Pay Now" : "Next"}
                  </Button>
                </div>
              </React.Fragment>
            )}
            <Typography variant="h9" gutterBottom>
              * Please enter none as your college name if you are not a college
              student and 00 in Roll.
            </Typography>
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  authToken: state.user.token,
});
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
