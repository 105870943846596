import React,{useContext} from "react";
import "../App.css";
import {paid} from "./PaymentSuccess";
import {loggedIn} from "./NewLogin";
import isAuthenticated from "../util/isAuthenticated";
import swal from "@sweetalert/with-react";
function Tickets() {
   const paymentSuccess=useContext(paid);
   const isloggedIn=useContext(loggedIn);
  // const paymentSuccess=true;
  // const isloggedIn=true;
  const handleGetTicketKSk = () => {
     swal(
       "Registration is closed",
       "Follow us on our social media handles for updates",
      "warning"
     );
    // window.location.href = "/checkout";
  
  };
  const handleGetTicketImps = () => {
    swal(
      "Payment will be live soon",
      "Stay tuned on our social media handles",
      "warning"
    );
  };
  if(isloggedIn && paymentSuccess){
  return (
    <div id="tickets" className="tickets">
        <h2 className="container" style={{textAlign:"center", fontSize:"1.3rem"}}>You have successfully registered and made your payment. See you at the event!</h2>
      </div>
    
                        
  );
                        }
  else{
    return(
      
      <div>
      <section id="tickets" className="tickets pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 mb-70 text-center">
              <h1 className="title gradient-text">
                Pricing for KIIT STARTUP KONCLAVE
              </h1>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row vertical-align tickets">
            {/* <div className="col-sm-4 ">
                     <h3 className="sub-title-0  mb-20"><span className="gradient-text">Pricing For</span><br/> 
                        <span className="gradient-text">KIIT STARTUP KONCLAVE </span>
                     </h3>
                     <div className="review-slider flexslider">
                        <ul className="slides">
                           <li>
                              <blockquote></blockquote>
                           </li>
                           <li>
                              <blockquote></blockquote>
                           </li>
                           <li>
                              <blockquote></blockquote>
                           </li>
                        </ul>
                     </div>
                  </div>  */}
            <div className="col-sm-12">
              <div className="col-sm-4">
                {/* <ul className="block-tickets overlap"> */}

                <ul className="block-ticket ">
                  <li className="block-price">
                    <span className="price">
                      <span className="cur">₹</span>249
                    </span>
                    <span className="block-type">KIIT Students</span>
                    <span className="block-type gradient-text">*Valid only with KIIT email ID</span>
                  </li>
                  <li> Access to all Speaker Session</li>
                  <li>Access to all workshops</li>
                  <li>
                    {isAuthenticated() ? (
                      <button
                        className="but mt-30"
                        onClick={handleGetTicketKSk}
                      >
                        {" "}
                        Buy Ticket Now
                      </button>
                    ) : (
                      <a href="#register" className="but mt-30">
                        {" "}
                        Buy Ticket Now
                      </a>
                    )}
                  </li>
                </ul>

                {/* </ul> */}
              </div>

              <div className="col-sm-4">
                <ul className="block-tickets">
                  <li>
                    <ul className="block-ticket">
                      <li className="block-price">
                        <span className="price">
                          <span className="cur">₹</span>299
                        </span>
                        <span className="block-type">Other Delegates</span>
                      </li>
                      <li>Access to all Speaker Sessions</li>
                      <li>Access to all Workshops</li>
                      <li>
                        {/* <a href="#" className="but mt-30">
                            {" "}
                            Buy Ticket Now
                          </a> */}
                        {isAuthenticated() ? (
                          <button
                            className="but mt-30"
                            onClick={handleGetTicketKSk}
                          >
                            {" "}
                            Buy Ticket Now
                          </button>
                        ) : (
                          <a href="#register" className="but mt-30">
                            {" "}
                            Buy Ticket Now
                          </a>
                        )}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4">
                <ul className="block-tickets">
                  <li>
                    <ul className="block-ticket">
                      
                      <li className="block-price">
                        
                        <span className="price">
                          <span className="cur">₹</span>498
                        </span>
                        <span style = {{textDecoration:"line-through"}}>₹698</span>
                        <span className="block-type gradient-text">*Valid only with KIIT Email ID</span>
                        
                      </li>
                      
                      <li>Startup Konclave</li>
                      <li>+</li>
                      <li>Internship Camp</li>
                      {/* <li>Access to all Workshops</li> */}
                      <li>
                        {/* <a href="#" className="but mt-30">
                            {" "}
                            Buy Ticket Now
                          </a> */}
                        {isAuthenticated() ? (
                          <button
                            className="but mt-30"
                            onClick={handleGetTicketKSk}
                          >
                            {" "}
                            Buy Ticket Now
                          </button>
                        ) : (
                          <a href="#register" className="but mt-30">
                            {" "}
                            Buy Ticket Now
                          </a>
                        )}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              <div className="col-sm-4">
                <ul className="block-tickets">
                  <li>
                    <ul className="block-ticket">
                      <li className="block-price">
                        <span className="price">
                          <span className="cur">₹</span>499
                        </span>
                        <span className="block-type">Both KIIT and Others</span>
                      </li>
                      <li>Access to all Speaker Sessions, Workshops</li>
                      <li>Access to IMPS, Virtual Booths, Support Point</li>
                      <li>
                        {/* <a href="#" className="but mt-30">
                          {" "}
                          Buy Ticket Now
                        </a> */}
                        {isAuthenticated() ? (
                          <button
                            className="but mt-30"
                            onClick={handleGetTicketKSk}
                          >
                            {" "}
                            Buy Ticket Now
                          </button>
                        ) : (
                          <a href="#register" className="but mt-30">
                            {" "}
                            Buy Ticket Now
                          </a>
                        )}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
  }
}

export default Tickets;
